<script setup>
import { CopyIcon, SlashIcon } from '@radix-icons/vue'
import { storeToRefs } from 'pinia'
import { computed, onMounted, reactive, ref } from 'vue'
import { toast } from 'vue-sonner'
import { CheckIcon } from '@heroicons/vue/24/solid'
import { useClipboard } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card/index.js'
import BaseError from '@/components/base/BaseError.vue'
import CampaignDomainSelect from '@/components/partials/campaign/CampaignDomainSelect.vue'
import { Alert, AlertTitle } from '@/components/ui/alert'
import { Label } from '@/components/ui/label/index.js'
import { Input } from '@/components/ui/input/index.js'
import Button from '@/components/ui/button/Button.vue'
import Field from '@/components/ui/field/Field.vue'
import { useCampaignStore } from '@/stores/campaign.js'
import { domainService } from '@/services/domain'
import CampaignEmptyDomainAlert from '@/components/partials/campaign/CampaignEmptyDomainAlert.vue'

const { copy, copied } = useClipboard()
const { t } = useI18n()
const campaignStore = useCampaignStore()
const { campaign } = storeToRefs(campaignStore)

const isSavingForm = ref(false)
const form = reactive({
  alias: campaign.value?.alias,
  domain: campaign.value?.domain
})
const errors = ref({})
const domains = ref(undefined)
const isLoadingDomains = ref(false)

const offerURL = computed(() => {
  if (!campaign.value?.domain || !campaign.value?.alias) {
    return
  }

  return `https://${campaign.value.domain}/${campaign.value.alias}`
})

async function onSubmit() {
  isSavingForm.value = true
  errors.value = {}

  try {
    await campaignStore.update(campaignStore.campaign.id, {
      ...campaignStore.campaign,
      alias: form.alias,
      domain: form.domain
    })

    toast.success(t('components.campaign_integration_domain.toast.success.title'), {
      description: t('components.campaign_integration_domain.toast.success.description'),
    })

    campaign.value.domain = form.domain
    campaign.value.alias = form.alias
  } catch (e) {
    if (e.response?.status === 422) {
      errors.value = e.response.data.data
    }

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isSavingForm.value = false
  }
}

async function fetchDomains() {
  isLoadingDomains.value = true

  try {
    const { data } = await domainService.findAll({
      page: 1,
      limit: 100
    })
    domains.value = data?.data.filter(domain => domain.verified) || []
  } catch (error) {
    console.error(error)
  } finally {
    isLoadingDomains.value = false
  }
}

onMounted(() => {
  fetchDomains()
})
</script>

<template>
  <div class=" max-w-2xl space-y-4">
    <form
      @submit.prevent="onSubmit"
    >
      <fieldset :disabled="isSavingForm">
        <Card>
          <CardHeader>
            <CardTitle>
              {{ $t('components.campaign_integration_domain.title') }}
            </CardTitle>
            <CardDescription>
              {{ $t('components.campaign_integration_domain.description') }}
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div class="flex max-w-xl gap-2">
              <Field class="flex-1">
                <Label for="domain">
                  {{ $t('common.domain') }}
                </Label>
                <div>
                  <CampaignDomainSelect
                    id="domain"
                    v-model="form.domain"
                    name="domain"
                    :domains="domains"
                    :disabled="isLoadingDomains || !domains?.length"
                    required
                  />
                </div>

                <BaseError v-if="errors?.domain">
                  {{ errors?.domain }}
                </BaseError>
              </Field>
              <Field class="flex-1">
                <Label for="alias">
                  URL
                </Label>
                <div>
                  <div class="relative ">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <SlashIcon class="size-4 text-neutral-400" />
                    </div>
                    <Input
                      id="alias"
                      v-model="form.alias"
                      name="alias"
                      placeholder="oferta"
                      required
                      class="py-1.5 pl-10"
                      :disabled="isLoadingDomains || !domains?.length"
                    />
                  </div>
                </div>
                <BaseError v-if="errors?.alias">
                  {{ errors?.alias }}
                </BaseError>
              </Field>
            </div>
          </CardContent>
          <CardFooter>
            <Button
              :loading="isSavingForm"
              :disabled="isLoadingDomains || !form.domain || !form.alias"
            >
              {{ $t('common.save') }}
            </Button>
          </CardFooter>
        </Card>
      </fieldset>
    </form>

    <div v-if="offerURL">
      <Label>
        {{ $t('components.campaign_integration_domain.announcement_link') }}
      </Label>
      <Alert class="flex items-center justify-between">
        <AlertTitle>
          {{ offerURL }}
        </AlertTitle>

        <Button
          size="sm"
          variant="secondary"
          @click="copy(offerURL)"
        >
          <CheckIcon
            v-if="copied"
            class="size-4"
          />
          <CopyIcon
            v-else
            class="size-4"
          />
        </Button>
      </Alert>
    </div>

    <div
      v-if="domains && !domains.length"
      class="mt-2 flex shrink-0"
    >
      <CampaignEmptyDomainAlert variant="info" />
    </div>
  </div>
</template>
