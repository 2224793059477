<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { ArrowDownIcon } from '@heroicons/vue/24/solid'
import { toast } from 'vue-sonner'
import { formatCurrency } from '@/helpers/currency'
import { formatNumber } from '@/helpers/format'
import { plansService } from '@/services/plans'
import { useUserStore } from '@/stores/user'
import Badge from '@/components/ui/badge/Badge.vue'
import Button from '@/components/ui/button/Button.vue'
import Label from '@/components/ui/label/Label.vue'
import Input from '@/components/ui/input/Input.vue'
import Field from '@/components/ui/field/Field.vue'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible'

const props = defineProps({
  plan: {
    type: Object,
    required: true
  },

})

const emit = defineEmits(['set-coupon'])
const route = useRoute()
const store = useUserStore()

const user = computed(() => store.user)

// Coupon
const searchCoupon = ref('')
const coupon = ref(null)
const isLoadingCoupon = ref(false)
const isOpen = ref(false)

async function findCoupon(code) {
  isLoadingCoupon.value = true
  try {
    const { data } = await plansService.validateCoupon(props.plan?.price_id, {
      code
    })
    coupon.value = data
    emit('set-coupon', data)

    toast.success('Sucesso!', {
      description: 'Cupom aplicado com sucesso!',
    })
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoadingCoupon.value = false
  }
}

function formatCouponValue(price, type) {
  if (type === 'percentage') {
    return `${price}%`
  }
  return `R$ ${price}`
}

function removeCoupon() {
  coupon.value = null
  emit('set-coupon', null)
}

const totalPrice = computed(() => {
  const price = props.plan?.price

  if (!coupon.value?.value || !coupon.value?.type) {
    return price
  }

  if (coupon.value?.type === 'percentage') {
    return price - (price * coupon.value?.value) / 100
  }

  return price
})

onMounted(() => {
  if (route.query?.coupon) {
    findCoupon(route.query.coupon)
  }
})
</script>

<template>
  <div>
    <section
      aria-labelledby="order-heading"
      class="border-b border-border bg-background px-4 py-6 sm:px-6 lg:hidden"
    >
      <Collapsible
        v-model:open="isOpen"
        class="mx-auto max-w-lg"
      >
        <CollapsibleTrigger class="w-full">
          <div>
            <div class="flex items-center justify-between">
              <h2
                id="order-heading"
                class="text-lg font-medium "
              >
                {{ $t('components.checkout_order_summary.your_order') }}
              </h2>
              <Button
                variant="secondary"
              >
                <ArrowDownIcon
                  class="mr-2 size-3"
                  :class="{ 'rotate-180': isOpen }"
                />
                <span v-if="isOpen">
                  {{ $t('components.checkout_order_summary.hide_summary') }}
                </span>
                <span v-if="!isOpen">
                  {{ $t('components.checkout_order_summary.show_summary') }}
                </span>
              </Button>
            </div>
          </div>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <div class="flex flex-col justify-between space-y-4 border-b border-border pb-4">
            <div class="space-y-1 text-sm font-medium">
              <h3 class="">
                {{ props.plan.name }}
              </h3>
              <p>
                {{ formatCurrency(props.plan.price, props.plan.currency) }} /
                {{ $t(`common.plan_cycle.${props.plan.cycle}`) }}
              </p>
              <p class="text-muted-foreground">
                {{ formatNumber(props.plan.limit) }} {{ $t('common.requests') }}
              </p>
              <p
                v-if="props.plan?.trial_days"
                class="text-muted-foreground"
              >
                {{ props.plan.trial_days }} {{ $t('components.checkout_order_summary.days_of_free_trial') }}
              </p>
            </div>
            <div class="flex space-x-4">
              <Button
                as-child
                variant="link"
              >
                <router-link
                  :to="{ name: 'CheckoutPlans' }"
                >
                  {{ $t('components.checkout_order_summary.change') }}
                </router-link>
              </Button>
            </div>
          </div>

          <form
            class="mt-10"
            @submit.prevent="findCoupon(searchCoupon)"
          >
            <fieldset :disabled="isLoadingCoupon">
              <Field>
                <Label for="discount-code">
                  {{ $t('components.checkout_order_summary.coupon') }}
                </Label>
                <div class="flex items-center space-x-4">
                  <div class="w-full">
                    <Input
                      id="discount-code"
                      v-model="searchCoupon"
                      type="text"
                      name="discount-code"
                      class="w-full"
                    />
                  </div>
                  <div>
                    <Button
                      type="submit"
                      :loading="isLoadingCoupon"
                    >
                      {{ $t('components.checkout_order_summary.apply') }}
                    </Button>
                  </div>
                </div>
              </Field>
            </fieldset>
          </form>
          <dl class="mt-10 space-y-6 text-sm font-medium text-muted-foreground">
            <div class="flex justify-between">
              <dt>
                {{ $t('components.checkout_order_summary.subtotal') }}
              </dt>
              <dd class="">
                {{ formatCurrency(props.plan.price, props.plan.currency) }}
              </dd>
            </div>
            <div
              v-if="coupon?.code"
              class="flex justify-between"
            >
              <dt class="flex gap-2">
                <span>
                  {{ $t('components.checkout_order_summary.discount') }}
                </span>
                <Badge
                  title="Remover cupom"
                  variant="success"
                >
                  {{ coupon.code }}
                  <button
                    class="pl-2"
                    @click="removeCoupon"
                  >
                    x
                  </button>
                </Badge>
              </dt>
              <dd class="">
                -{{ formatCouponValue(coupon.value, coupon.type) }}
              </dd>
            </div>
          </dl>
        </CollapsibleContent>
      </Collapsible>
    </section>

    <!-- Order summary -->
    <section
      aria-labelledby="summary-heading"
      class="bg-background-50 hidden min-h-screen w-full max-w-md flex-col lg:flex"
    >
      <h2
        id="summary-heading"
        class="sr-only"
      >
        Order summary
      </h2>

      <div class="flex space-x-6 py-6 pl-5">
        <div class="flex flex-col justify-between space-y-4">
          <div class="space-y-1 text-sm font-medium">
            <h3 class="">
              {{ props.plan.name }}
            </h3>
            <p>
              {{ formatCurrency(props.plan.price, props.plan.currency) }} /
              {{ $t(`common.plan_cycle.${props.plan.cycle}`) }}
            </p>
            <p
              v-if="props.plan.has_limit"
              class="text-muted-foreground"
            >
              {{ formatNumber(props.plan.limit) }} {{ $t('common.requests') }}
            </p>
            <p
              v-if="props.plan?.trial_days"
              class="text-muted-foreground"
            >
              {{ props.plan.trial_days }} {{ $t('components.checkout_order_summary.days_of_free_trial') }}
            </p>
          </div>
          <div class="flex space-x-4">
            <router-link
              :to="{ name: 'CheckoutPlans' }"
              class="text-primary-600 text-sm font-medium hover:text-primary"
            >
              {{ $t('components.checkout_order_summary.change') }}
            </router-link>
          </div>
        </div>
      </div>

      <div
        class="sticky bottom-0 flex-none border-t border-border bg-background p-6"
      >
        <form @submit.prevent="findCoupon(searchCoupon)">
          <fieldset :disabled="isLoadingCoupon">
            <Field>
              <Label for="discount-code">
                {{ $t('components.checkout_order_summary.coupon') }}
              </Label>
              <div class="mt-2 flex items-center space-x-4">
                <div class="w-full">
                  <Input
                    id="discount-code"
                    v-model="searchCoupon"
                    type="text"
                    name="discount-code"
                  />
                </div>
                <div>
                  <Button
                    type="submit"
                    :loading="isLoadingCoupon"
                  >
                    {{ $t('components.checkout_order_summary.apply') }}
                  </Button>
                </div>
              </div>
            </Field>
          </fieldset>
        </form>

        <dl class="mt-20 space-y-6 text-sm font-medium text-muted-foreground">
          <div class="flex justify-between">
            <dt>
              {{ $t('components.checkout_order_summary.subtotal') }}
            </dt>
            <dd class="">
              {{ formatCurrency(props.plan.price) }}
            </dd>
          </div>

          <div
            v-if="coupon?.type"
            class="flex justify-between"
          >
            <dt class="flex gap-2">
              <span>
                {{ $t('components.checkout_order_summary.discount') }}
              </span>
              <Badge variant="success">
                {{ coupon.code }}
                <button
                  title="Remover cupom"
                  class="pl-2"
                  @click="removeCoupon"
                >
                  x
                </button>
              </Badge>
            </dt>
            <dd class="">
              -{{ formatCouponValue(coupon.value, coupon.type) }}
            </dd>
          </div>
          <div
            class="flex items-center justify-between border-t border-border pt-6 "
          >
            <dt>
              {{ $t('components.checkout_order_summary.total') }}
            </dt>
            <dd class="text-base">
              {{ formatCurrency(totalPrice, props.plan.currency) }}
            </dd>
          </div>
        </dl>
      </div>
    </section>
  </div>
</template>
