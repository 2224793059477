<script setup>
import { computed, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useColorMode } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import BlankLayout from './layouts/BlankLayout.vue'
import { useLocaleCookie } from './composables/useLocaleCookie'
import { useUserStore } from './stores/user'
import SessionExpiredAlertDialog from './components/layout/SessionExpiredAlertDialog.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'
import CheckoutLayout from '@/layouts/CheckoutLayout.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import SafePageGenerator from '@/layouts/SafePageGenerator.vue'
import { Toaster } from '@/components/ui/sonner'

const layouts = {
  default: DefaultLayout,
  auth: AuthLayout,
  checkout: CheckoutLayout,
  safePageGenerator: SafePageGenerator,
  blank: BlankLayout
}

const route = useRoute()
const layout = computed(() => layouts[route.meta.layout])

const theme = useColorMode()
const { locale } = useI18n()
const userStore = useUserStore()

onMounted(() => {
  locale.value = useLocaleCookie().getCookie() ?? window.navigator.language
})

watch(
  () => userStore.user.language,
  (newLocale) => {
    if (newLocale) {
      locale.value = newLocale
      useLocaleCookie().setCookie(newLocale)
      useHead({
        htmlAttrs: {
          lang: newLocale
        }
      })
    }
  },
  {
    immediate: true,
    deep: true
  }
)
</script>

<template>
  <div>
    <Toaster
      :theme="theme"
      class="pointer-events-auto"
    />
    <component :is="layout" />
    <SessionExpiredAlertDialog />
  </div>
</template>
