<script setup>
import dayjs from 'dayjs'
import { CalendarDaysIcon } from '@heroicons/vue/24/solid'
import { computed } from 'vue'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/v-calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

const props = defineProps({
  presets: {
    type: Array,
    default: () => [],
  },
  min: {
    type: Date,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  locale: {
    type: String,
    default: 'pt-BR'
  },
  modelValue: {
    type: Object,
    default: () => ({
      start: null,
      end: null,
    }),
  },
})
const emit = defineEmits(['update:modelValue'])

const date = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', {
      start_date: value.start_date ? dayjs(value.start_date).format('YYYY-MM-DD HH:mm:ss') : null,
      end_date: value.end_date ? dayjs(value.end_date).format('YYYY-MM-DD HH:mm:ss') : null,
    })
  },
})

function updateDate(value) {
  const presetDate = props.presets.find(preset => preset.id === value)
  if (presetDate) {
    date.value = {
      start_date: presetDate.value.start,
      end_date: presetDate.value.end
    }
  }
}

function updateEndDate(value) {
  date.value = {
    start_date: date.value.start_date,
    end: value
  }
}

function updateStartDate(value) {
  date.value = {
    start: value,
    end_date: date.value.end_date
  }
}
</script>

<template>
  <div :class="cn('grid gap-2', $attrs.class ?? '')">
    <Popover>
      <PopoverTrigger as-child>
        <Button
          id="date"
          variant="outline"
          :class="cn(
            ' justify-start text-left font-normal word-break truncate',
            !date && 'text-muted-foreground',
          )"
          :disabled="disabled"
          :loading="loading"
        >
          <CalendarDaysIcon
            v-if="!loading"
            class="mr-2 size-5 shrink-0"
          />

          <span class="word-break truncate ">
            {{ date.start ? (
              date.end ? `${dayjs(date.start).format('DD/MM/YYYY HH:mm')} - ${dayjs(date.end).format('DD/MM/YYYY HH:mm')}`
              : dayjs(date.start).format('DD/MM/YYYY HH:mm')
            ) : $t('components.date_picker.select_a_date') }}
          </span>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        class="flex w-auto max-w-xs flex-col space-y-2 p-2"
        align="start"
      >
        <Select
          v-if="props.presets.length"
          @update:model-value="updateDate"
        >
          <SelectTrigger>
            <SelectValue
              :placeholder="$t('components.date_picker.select_predefined_date')"
            />
          </SelectTrigger>
          <SelectContent position="popper">
            <SelectItem
              v-for="item in props.presets"
              :key="item.value"
              :value="item.id"
            >
              {{ item.label }}
            </SelectItem>
          </SelectContent>
        </Select>

        <Calendar
          v-model.range="date"
          :min-date="props.min"
          :max-date="new Date()"
          is24hr
          :locale="locale"
        >
          <template #footer>
            <div class="mt-6 flex w-full border-t border-accent pt-4">
              <div class="w-1/2">
                <strong>
                  {{ $t('common.start') }}
                </strong>
                <Calendar
                  mode="time"
                  :model-value="date.start"
                  is24hr
                  hide-time-header
                  :locale="locale"
                  @update:model-value="updateStartDate"
                />
              </div>
              <div class="w-1/2">
                <strong>
                  {{ $t('common.end') }}
                </strong>
                <Calendar
                  mode="time"
                  :model-value="date.end"
                  is24hr
                  hide-time-header
                  :locale="locale"
                  @update:model-value="updateEndDate"
                />
              </div>
            </div>
          </template>
        </Calendar>
      </PopoverContent>
    </Popover>
  </div>
</template>

<style scoped>
select::-ms-expand {
    display: none;
}
</style>
