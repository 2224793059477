<script setup>
import { nextTick, onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { loadStripe } from '@stripe/stripe-js'
import { toast } from 'vue-sonner'
import { useColorMode } from '@vueuse/core'
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import AvailableCards from '@/components/AvailableCards.vue'
import CheckoutOrderSummary from '@/components/partials/checkout/CheckoutOrderSummary.vue'
import client from '@/plugins/axios.js'
import { subscriptionService } from '@/services/subscription'
import { useAuthStore } from '@/stores/auth'
import { plansService } from '@/services/plans'
import { Skeleton } from '@/components/ui/skeleton'
import { cardService } from '@/services/card'
import { useUserStore } from '@/stores/user'
import FormAddress from '@/components/partials/FormAddress.vue'
import { documentMask } from '@/helpers/masks'
import Button from '@/components/ui/button/Button.vue'
import Label from '@/components/ui/label/Label.vue'
import ErrorMessage from '@/components/ui/error-message/ErrorMessage.vue'
import Input from '@/components/ui/input/Input.vue'
import Field from '@/components/ui/field/Field.vue'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { dirto } from '@/plugins/dirto'

const props = defineProps({
  priceId: {
    type: String,
    required: true
  }
})

const { t, locale } = useI18n()
useHead({
  title: t('pages.checkout.title')
})

const router = useRouter()
const authStore = useAuthStore()
const userStore = useUserStore()
const colorMode = useColorMode()

let stripe = null
const form = reactive({
  plan: props.priceId,
  card: {
    token: 'new',
    holderName: ''
  },
  address: {
    postal_code: userStore.user.address?.postal_code ?? '',
    number: userStore.user.address?.number ?? '',
    street: userStore.user.address?.street ?? '',
    district: userStore.user.address?.district ?? '',
    complement: userStore.user.address?.complement ?? '',
    city: userStore.user.address?.city ?? '',
    state: userStore.user.address?.state ?? '',
    country: userStore.user.address?.country ?? 'BR'
  },
  document: userStore.user.document ?? '',
  coupon: '',
  radar: '',
})
const elementReady = reactive({
  number: false,
  exp: false,
  cvc: false
})
const isLoading = ref(false)
const plan = ref({})
const stripeError = ref('')
const cards = ref([])
const errors = ref({})

/**
 * @param {string} id
 */
async function fetchPlan(id) {
  try {
    const { data } = await plansService.findById(id)
    plan.value = data
  } catch (e) {
    await router.push('/plans')

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  }
}

/**
 *
 * @param {object} coupon
 * @param {string} coupon.code
 * @param {number} coupon.value
 * @param {string} coupon.type
 */
function setCoupon(coupon) {
  form.coupon = coupon
}

function trackConversion() {
  try {
    dirto().track('conversion', {
      v_name: userStore.user?.name,
      v_email: userStore.user?.email,
      v_phone: userStore.user?.phone,
      currency: plan.value?.currency,
      value: plan.value?.price,
      product: plan.value?.name
    })

    const firstName = userStore.user.name?.split(' ')[0]
    const LastName = userStore.user.name?.split(' ')[1]

    gtag('set', 'user_data', {
      email: userStore.user.email,
      phone_number: userStore.user.phone,
      address: {
        first_name: firstName,
        last_name: LastName,
      }
    })

    gtag('event', 'conversion', {
      send_to: 'AW-16571741400/gJ3TCPmYo8EZENjpgt49',
      value: plan.value?.price,
      currency: plan.value?.currency
    })
  } catch (error) {
    console.error('Error tracking conversion', error)
  }
}

async function confirmWith3DSecure(error, data) {
  try {
    const confirmResponse = await stripe.confirmCardPayment(
      error.response.data.data.client_secret
    )

    if (confirmResponse.error) {
      throw new Error(error.message)
    }

    if (confirmResponse.paymentIntent.status !== 'succeeded') {
      throw new Error('Payment failed')
    }

    await client.post('/subscriptions/confirm', {
      paymentIntentId: confirmResponse.paymentIntent.id,
      planId: props.priceId,
      ...data
    })
    await authStore.checkAuth()

    trackConversion()

    toast.success(t('pages.checkout.toast.success.title'), {
      description: t('pages.checkout.toast.success.description'),
    })

    await router.push({
      name: 'Dashboard'
    })
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  }
}

async function setupRadarSession() {
  const { radarSession, error } = await stripe.createRadarSession()
  if (error) {
    console.error(error)
    return
  }

  form.radar = radarSession.id
}

async function setupCardElements() {
  await nextTick()
  if (!stripe) {
    return
  }

  const elements = stripe.elements({
    fonts: [
      {
        cssSrc: 'https://rsms.me/inter/inter.css'
      }
    ],
    locale: 'auto'
  })

  const cardNumberElement = document.getElementById('stripe-card')
  const cardExpiryElement = document.getElementById('stripe-exp')
  const cardCvcElement = document.getElementById('stripe-cvc')

  const themeDark = {
    color: '#fff',
    iconColor: '#fff',
  }

  const themeLight = {
    color: '#000',
    iconColor: '#000',
  }

  const style = {
    base: {
      fontFamily: 'Inter, Open Sans, Segoe UI, sans-serif',
      fontSmoothing: 'antialiased',
      ...(colorMode.value === 'dark' ? themeDark : themeLight)
    }
  }

  const cardNumber = elements.create('cardNumber', {
    showIcon: true,
    style
  })
  const cardExpiry = elements.create('cardExpiry', {
    style
  })
  const cardCvc = elements.create('cardCvc', {
    style
  })

  if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
    return
  }

  cardNumber.mount(cardNumberElement)
  cardExpiry.mount(cardExpiryElement)
  cardCvc.mount(cardCvcElement)

  cardNumber.on('ready', () => {
    elementReady.number = true
  })

  cardNumber.on('change', (event) => {
    const displayError = document.getElementById('card-number-errors')
    if (!displayError) {
      return
    }

    if (event.error) {
      displayError.textContent = event.error.message
    } else {
      displayError.textContent = ''
    }
  })

  cardExpiry.on('ready', () => {
    elementReady.exp = true
  })

  cardExpiry.on('change', (event) => {
    const displayError = document.getElementById('card-exp-errors')
    if (!displayError) {
      return
    }

    if (event.error) {
      displayError.textContent = event.error.message
    } else {
      displayError.textContent = ''
    }
  })

  cardCvc.on('ready', () => {
    elementReady.cvc = true
  })

  cardCvc.on('change', (event) => {
    const displayError = document.getElementById('card-cvc-errors')
    if (!displayError) {
      return
    }

    if (event.error) {
      displayError.textContent = event.error.message
    } else {
      displayError.textContent = ''
    }
  })

  // Create a token or display an error when the form is submitted.
  const formElement = document.getElementById('payment-form')
  if (!formElement) {
    return
  }

  formElement.addEventListener('submit', async (event) => {
    event.preventDefault()

    if (!stripe) {
      return
    }
    stripeError.value = null
    const disableInputs = {
      disabled: true
    }

    cardCvc.update(disableInputs)
    cardExpiry.update(disableInputs)
    cardNumber.update(disableInputs)

    const enabledInput = {
      disabled: false
    }

    let payload = {
      token: form.card.token,
      plan: props.priceId,
      coupon: form.coupon?.code,
      radar: form.radar,
      address: {
        postal_code: form.address?.postal_code?.replace(/\D/g, ''),
        number: form.address?.number,
        street: form.address?.street,
        district: form.address?.district,
        complement: form.address?.complement,
        city: form.address?.city,
        state: form.address?.state,
        country: form.address?.country
      },
      document: form.document?.replace(/\D/g, '')
    }

    // create new card
    isLoading.value = true
    if (form.card.token === 'new') {
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumber,
        billing_details: {
          name: form.card.holderName,
          email: userStore.user.email,
          ...(userStore.user?.phone && {
            phone: userStore.user.phone
          }),
          address: {
            city: payload.address?.city,
            line1: `${payload.address?.street}, ${payload.address?.number}, ${
              payload.address?.district ? payload.address?.district : ''
            }`,
            line2: payload.address?.complement,
            state: payload.address?.state,
            postal_code: payload.address?.postal_code
          }
        },
        ...(form.radar && {
          radar_options: {
            session: form.radar,
          },
        }),
      })

      if (result.error) {
        isLoading.value = false
        stripeError.value = result.error.message

        toast.error('Erro!', {
          description: result.error.message,
        })

        cardCvc.update(enabledInput)
        cardExpiry.update(enabledInput)
        cardNumber.update(enabledInput)

        return
      }

      try {
        const newCard = await attachPaymentMethod({
          paymentMethod: result.paymentMethod.id,
          radar: form.radar
        })

        form.card.token = newCard.data.token
        cards.value = [...cards.value, newCard?.data]

        payload = {
          ...payload,
          token: result.paymentMethod.id
        }
      } catch (e) {
        isLoading.value = false

        toast.error(e.response?.data?.message, {
          description: e.response?.data?.action || e.message,
        })

        cardCvc.update(enabledInput)
        cardExpiry.update(enabledInput)
        cardNumber.update(enabledInput)
        return
      }
    }

    await createSubscription(payload)

    isLoading.value = false
    cardCvc.update(enabledInput)
    cardExpiry.update(enabledInput)
    cardNumber.update(enabledInput)
  })
}

/**
 *
 * @param {object} data
 * @param {string} data.paymentMethod
 * @param {string} data.radar
 */
async function attachPaymentMethod(data) {
  return await cardService.create({
    payment_method: data.paymentMethod,
    radar_session: data.radar
  })
}

/**
 * @param {object} data
 * @param {string} data.plan
 * @param {string} data.token
 * @param {string} data.coupon
 * @param {string} data.radar
 * @param {object} data.address
 * @param {string} data.document
 */
async function createSubscription(data) {
  try {
    await subscriptionService.create(data)
    await authStore.checkAuth()

    toast.success(t('pages.checkout.toast.success.title'), {
      description: t('pages.checkout.toast.success.description'),
    })

    trackConversion()

    await router.push({
      name: 'Dashboard'
    })
  } catch (error) {
    if (error.response.status === 400) {
      if (error.response.data?.data?.client_secret) {
        await confirmWith3DSecure(error, data)
        return
      }
    }

    if (error.response.status === 422) {
      errors.value = error.response.data.data
    }

    toast.error('Erro!', {
      description: error.response?.data?.message || error.message,
    })
  }
}

await fetchPlan(props.priceId)

onMounted(async () => {
  stripe = await loadStripe(import.meta.env.VITE_STRIPE_PK)
  await setupRadarSession()
  await setupCardElements()

  dirto().track('start_checkout', {
    v_email: userStore.user?.email,
    v_phone: userStore.user?.phone,
    v_name: userStore.user?.name,
    currency: plan.value?.currency,
    value: plan.value?.price,
    product: plan.value?.name
  })
})
</script>

<template>
  <main class="bg-background lg:flex lg:min-h-full lg:flex-row-reverse lg:overflow-hidden">
    <h1 class="sr-only">
      Checkout
    </h1>

    <div class="bg-background px-4 py-6 sm:px-6 lg:hidden">
      <div class="mx-auto flex max-w-lg">
        <RouterLink to="/">
          <span class="sr-only">Cloakup</span>
          <img
            src="../assets/img/logo-black.svg"
            alt="Cloakup"
            class="h-8 w-auto dark:invert"
          >
        </RouterLink>
      </div>
    </div>

    <div class="bg-background lg:h-screen">
      <CheckoutOrderSummary
        :plan="plan"
        @set-coupon="setCoupon"
      />
    </div>

    <div
      class="flex-auto border-r border-border px-4 pb-16 pt-12 shadow sm:px-6 sm:pt-16 lg:h-screen lg:overflow-y-auto lg:px-8 lg:pb-24 lg:pt-0"
    >
      <section
        aria-labelledby="payment-heading"
        class="mx-auto max-w-lg"
      >
        <div class="hidden pb-16 pt-10 lg:flex">
          <RouterLink to="/">
            <span class="sr-only">Cloakup</span>
            <img
              src="../assets/img/logo-black.svg"
              alt="Cloakup"
              class="h-8 w-auto dark:invert"
            >
          </RouterLink>
        </div>
        <h2
          id="payment-heading"
          class="sr-only"
        >
          Payment
        </h2>

        <div>
          <form id="payment-form">
            <fieldset
              :disabled="isLoading"
              class="space-y-12"
            >
              <div class="border-b border-border pb-12">
                <h3 class="text-lg font-semibold leading-7 ">
                  {{ $t("pages.checkout.billing_address") }}
                </h3>
                <div class="mt-6">
                  <FormAddress
                    v-model="form.address"
                    :is-intl="locale !== 'pt-BR'"
                    :errors="errors"
                  />
                </div>
              </div>

              <div class="mt-10">
                <h3 class="text-lg font-semibold leading-7 ">
                  {{ $t("pages.checkout.payment_method") }}
                </h3>
                <div class="mt-6">
                  <AvailableCards
                    v-model="form.card.token"
                    :cards="cards"
                  />

                  <div
                    v-show="form.card.token === 'new'"
                    class="mt-4"
                  >
                    <div class="grid grid-cols-1 gap-4 sm:grid-cols-6">
                      <div class="sm:col-span-6">
                        <Field>
                          <Label for="card-name">
                            {{ $t("components.payment_method.holder_name") }}
                          </Label>
                          <div>
                            <Skeleton
                              v-show="
                                !elementReady.number
                                  && !elementReady.cvc
                                  && !elementReady.exp
                              "
                              class="h-10 w-full rounded-md"
                            />
                            <Input
                              v-show="
                                elementReady.number
                                  && elementReady.cvc
                                  && elementReady.exp
                              "
                              id="card-name"
                              v-model="form.card.holderName"
                              name="card-name"
                              type="text"
                            />
                          </div>
                        </Field>
                      </div>
                      <div class="stripe-custom-element sm:col-span-6">
                        <Field>
                          <Label for="stripe-card">
                            {{ $t("components.payment_method.card_number") }}
                          </Label>
                          <div>
                            <Skeleton
                              v-show="!elementReady.number"
                              class="h-10 w-full rounded-md"
                            />
                            <div
                              v-show="elementReady.number"
                              id="stripe-card"
                              class="input"
                            />
                            <span
                              id="card-number-errors"
                              class="mt-2 text-sm text-red-600"
                            />
                          </div>
                        </Field>
                      </div>
                      <div class="stripe-custom-element sm:col-span-3">
                        <Field>
                          <Label for="stripe-exp">
                            {{ $t("components.payment_method.expiration_date") }}
                          </Label>

                          <div>
                            <Skeleton
                              v-show="!elementReady.exp"
                              class="h-10 w-full rounded-md"
                            />
                            <div
                              v-show="elementReady.exp"
                              id="stripe-exp"
                              class="input"
                            />
                            <span
                              id="card-exp-errors"
                              class="mt-2 text-sm text-red-600"
                            />
                          </div>
                        </Field>
                      </div>
                      <div class="stripe-custom-element sm:col-span-3">
                        <Field>
                          <Label for="stripe-cvc">
                            {{ $t("components.payment_method.cvc_code") }}
                          </Label>
                          <div>
                            <Skeleton
                              v-show="!elementReady.cvc"
                              class="h-10 w-full rounded-md"
                            />
                            <div
                              v-show="elementReady.cvc"
                              id="stripe-cvc"
                              class="input"
                            />
                            <span
                              id="card-cvc-errors"
                              class="mt-2 text-sm text-red-600"
                            />
                          </div>
                        </Field>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="form.address?.country === 'BR'"
                    class="mt-4"
                  >
                    <Field>
                      <Label
                        required
                        for="document"
                      >
                        {{ $t("pages.checkout.tax_id") }}
                      </Label>
                      <Input
                        id="document"
                        v-model="form.document"
                        v-maska="documentMask"
                        type="tel"
                        name="document"
                        placeholder="CPF/CNPJ"
                        required
                      />
                      <ErrorMessage :message="errors?.document" />
                    </Field>
                  </div>
                </div>
              </div>

              <div
                v-if="stripeError"
                class="pt-5"
              >
                <Alert variant="destructive">
                  <ExclamationTriangleIcon class="size-4" />
                  <AlertTitle>Atenção</AlertTitle>
                  <AlertDescription>
                    {{ stripeError }}
                  </AlertDescription>
                </Alert>
              </div>

              <div class="pt-2">
                <Button
                  type="submit"
                  :loading="isLoading"
                  class="w-full"
                >
                  {{ $t("pages.checkout.submit") }}
                </Button>
              </div>
            </fieldset>
          </form>

          <a
            href="https://stripe.com/"
            target="_blank"
            class="flex flex-col items-center justify-center gap-2 pt-12"
          >
            <span class="text-xs text-muted-foreground">{{ $t("pages.checkout.payment_process_by") }}</span>
            <img
              src="../assets/img/stripe-logo.svg"
              class="h-12"
              alt="Stripe"
            >
          </a>
        </div>
      </section>
    </div>
  </main>
</template>

<style scoped>
.input {
  @apply rounded-md px-3 py-3 ring-border border-primary block w-full border-0 text-sm leading-6 caret-primary accent-primary shadow-sm ring-1 ring-inset text-white placeholder:text-muted-foreground dark:text-neutral-50 ;
}

.StripeElement {
    color: hsl(var(--input));
}

.StripeElement--invalid {
  box-shadow: 0 0 0 1px hsl(var(--destructive)) inset;
  color: hsl(var(--destructive));
}

.StripeElement--focus {
  box-shadow: 0 0 0 1.5px hsl(var(--primary)) inset;
  color: hsl(var(--primary));
}
</style>
