<script setup>
import { reactive, ref } from 'vue'
import dayjs from 'dayjs'
import { FunnelIcon } from '@heroicons/vue/24/solid'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import Datepicker from '@/components/base/DatePicker.vue'
import { allowedOptions, devicesOptions } from '@/static/filters'
import Input from '@/components/ui/input/Input.vue'
import Label from '@/components/ui/label/Label.vue'
import Button from '@/components/ui/button/Button.vue'
import Field from '@/components/ui/field/Field.vue'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'
import { usePresetDates } from '@/composables/usePresetDates'

const router = useRouter()
const route = useRoute()
const {
  minDate,
  shortcuts
} = usePresetDates()
const { locale } = useI18n()

const filters = reactive({
  allowed: route.query.allowed ?? 'all',
  date: {
    start: route.query.start_date ?? dayjs(shortcuts.value[2].value.start).format('YYYY-MM-DD HH:mm:ss'),
    end: route.query.end_date ?? dayjs(shortcuts.value[2].value.end).format('YYYY-MM-DD HH:mm:ss')
  },
  device: route.query.device ?? 'all',
  domain: route.query.domain ?? undefined,
  referer: route.query.referer ?? undefined
})
const isOpen = ref(false)

function filter() {
  router.push({
    query: {
      cursor: undefined, // remove cursor from query
      allowed: filters.allowed !== 'all' ? filters.allowed : undefined,
      device: filters.device !== 'all' ? filters.device : undefined,
      domain: filters.domain,
      referer: filters.referer,
      start_date: filters.date.start,
      end_date: filters.date.end,
    }
  })

  isOpen.value = false
}

function reset() {
  filters.allowed = 'all'
  filters.device = 'all'
  filters.domain = undefined
  filters.referer = undefined
  filters.date.start = shortcuts.value[2].value.start
  filters.date.end = shortcuts.value[2].value.end

  router.push({
    query: {}
  })

  isOpen.value = false
}
</script>

<template>
  <Sheet v-model:open="isOpen">
    <SheetTrigger>
      <Button>
        <FunnelIcon class="mr-2 size-5" />
        {{ $t('common.filters') }}
      </Button>
    </SheetTrigger>
    <SheetContent>
      <SheetHeader>
        <SheetTitle>
          {{ $t('components.campaign_request_filters.title') }}
        </SheetTitle>
        <SheetDescription>
          {{ $t('components.campaign_request_filters.description') }}
        </SheetDescription>
      </SheetHeader>

      <div class="mt-4 space-y-4">
        <Field>
          <Label>
            {{ $t('common.date') }}
          </Label>
          <Datepicker
            v-model="filters.date"
            :presets="shortcuts"
            :min="minDate"
            :locale="locale"
          />
        </Field>
        <Field>
          <Label for="allowed">
            {{ $t('common.status') }}
          </Label>
          <Select v-model="filters.allowed">
            <SelectTrigger
              id="allowed"
              name="allowed"
            >
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem
                  v-for="options in allowedOptions"
                  :key="String(options.id)"
                  :value="String(options.id)"
                >
                  {{ $t(`common.alloweds.${String(options.id)}`) }}
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </Field>
        <Field>
          <Label for="device">
            {{ $t('common.device') }}
          </Label>
          <Select
            id="device"
            v-model="filters.device"
          >
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem
                  v-for="option in devicesOptions"
                  :key="option.id"
                  :value="option.id"
                >
                  {{ $t(`common.devices.${option.id}`) }}
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </Field>
        <Field>
          <Label for="referer">
            {{ $t('common.referrer') }}
          </Label>
          <Input
            id="referer"
            v-model="filters.referer"
            type="text"
            name="referer"
          />
        </Field>
        <Field>
          <Label for="domain">
            {{ $t('common.domain') }}
          </Label>
          <Input
            id="domain"
            v-model="filters.domain"
            type="text"
            name="domain"
          />
        </Field>

        <div class="flex items-center gap-2">
          <Button @click="filter">
            {{ $t('common.filter') }}
          </Button>
          <Button
            variant="secondary"
            @click="reset"
          >
            {{ $t('common.reset') }}
          </Button>
        </div>
      </div>
    </SheetContent>
  </Sheet>
</template>
