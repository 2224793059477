<script setup>
import { ref } from 'vue'
import { CaretSortIcon, CheckIcon } from '@radix-icons/vue'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'

const props = defineProps({
  options: {
    required: true,
    type: Array
  },
  placeholder: {
    type: String,
    default: 'Select framework...',
  },
  empty: {
    type: String,
    default: 'No framework found.',
  },
  invalid: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const value = defineModel({
  required: true
})

const open = ref(false)
</script>

<template>
  <Popover v-model:open="open">
    <PopoverTrigger as-child>
      <Button
        variant="outline"
        :disabled="disabled"
        role="combobox"
        :aria-expanded="open"
        class="w-full justify-between font-normal"
        :class="[
          invalid
            ? 'border-red-500 text-red-500 hover:text-red-500'
            : '',
        ]"
      >
        <p class="truncate">
          {{ value
            ? props.options.find((option) => option.id === value)?.name
            : props.placeholder }}
        </p>
        <CaretSortIcon class="ml-2 size-4 shrink-0 opacity-50" />
      </Button>
    </PopoverTrigger>
    <PopoverContent class="w-[200px] p-0">
      <Command>
        <CommandInput
          class="h-9 border-none bg-transparent"
          :placeholder="props.placeholder"
        />
        <CommandEmpty>
          {{ props.empty }}
        </CommandEmpty>
        <CommandList>
          <CommandGroup>
            <CommandItem
              v-for="option in props.options"
              :key="option.id"
              :value="option.name"
              @select="(ev) => {
                value = option.id
                open = false
              }"
            >
              {{ option.name }}
              <CheckIcon
                :class="cn(
                  'ml-auto h-4 w-4',
                  value === option.id ? 'opacity-100' : 'opacity-0',
                )"
              />
            </CommandItem>
          </CommandGroup>
        </CommandList>
      </Command>
    </PopoverContent>
  </Popover>
</template>
