import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import { useUserStore } from './user'
import { clarity } from '@/plugins/clarity'
import { crisp } from '@/plugins/crisp'
import { authService } from '@/services/auth'

export const useAuthStore = defineStore('auth', () => {
  const { updateUser, fetchUsage } = useUserStore()
  const isExpired = ref(false)

  const token = ref(localStorage.getItem('clkp-token'))

  async function checkAuth() {
    if (!token.value) {
      return
    }

    try {
      const { data } = await authService.me()
      updateUser(data)
      fetchUsage()
      setCrispData(data)
    } catch (error) {
      await signOut()
    }
  }

  function setCrispData({ name, email, subscriptions }) {
    crisp().setUserName(name)
    crisp().setUserEmail(email)
    if (subscriptions?.length) {
      try {
        crisp().setSubscription(subscriptions)
      } catch (e) {
        console.log(e)
      }
    }
    clarity().setEmail(email)
  }

  async function signOut() {
    token.value = null
  }

  watch(token, () => {
    if (!token.value) {
      localStorage.removeItem('clkp-token')
      updateUser({
        name: '',
        email: '',
        custom_domain_enabled: false,
        cloakup_active: false,
        host_active: false,
        subscriptions: [
          {
            plan: {}
          }
        ]
      })
      return
    }
    localStorage.setItem('clkp-token', token.value)
  })

  return {
    token,
    isExpired,
    signOut,
    checkAuth
  }
})
