<script setup>
import { ref, watch } from 'vue'
import { toast } from 'vue-sonner'
import { ArrowDownTrayIcon, DocumentDuplicateIcon, EyeIcon, PencilIcon, PlusIcon } from '@heroicons/vue/24/solid'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import Button from '@/components/ui/button/Button.vue'
import BaseTable from '@/components/base/BaseTable.vue'
import PageHeading from '@/components/common/PageHeading.vue'
import Pagination from '@/components/common/Pagination.vue'
import CampaignStatusBadge from '@/components/partials/campaign/CampaignStatusBadge.vue'
import DeleteCampaignModal from '@/components/partials/campaign/DeleteCampaignModal.vue'
import { formatDate } from '@/helpers/date'
import router from '@/router'
import { campaignService } from '@/services/campaign'
import EmptyState from '@/components/common/EmptyState.vue'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import { useCampaignStore } from '@/stores/campaign'
import CampaignCreateButtonModal from '@/components/partials/campaign/CampaignCreateButtonModal.vue'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'

const { t, locale } = useI18n()
useHead({
  title: t('pages.campaign.title')
})

const campaignTableHeader = [
  {
    label: t('common.created_at'),
    value: 'created_at',
    format: value => formatDate(value, {
      locale: locale.value
    })
  },
  {
    label: t('common.name'),
    value: 'name'
  },
  {
    label: t('common.status'),
    value: 'active'
  }
]

const route = useRoute()
const campaignStore = useCampaignStore()

const count = ref(0)
const campaigns = ref([])
const isLoading = ref(false)
const hasError = ref(false)
const isCloning = ref(false)

async function fetchCampaigns(params) {
  isLoading.value = true
  try {
    const { data } = await campaignService.findAll(params)
    campaigns.value = data.data
    count.value = data.count
    campaignStore.setCampaigns(data.data)
  } catch (e) {
    hasError.value = true

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}

async function cloneCampaign(id) {
  isCloning.value = true
  try {
    await campaignService.clone(id)

    toast.success(t('pages.campaign.clone.toast.success.title'), {
      description: t('pages.campaign.clone.toast.success.description'),
    })

    fetchCampaigns({
      page: route.query.page,
      limit: route.query.limit,
    })
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isCloning.value = false
  }
}

function refresh() {
  fetchCampaigns({
    page: route.query.page,
    limit: route.query.limit
  })
}

function onStatusChanged(status) {
  router.push({
    query: {
      ...route.query,
      active: status === 'all' ? undefined : status
    }
  })
}

watch(
  () => [route.query],
  () => {
    fetchCampaigns({
      page: route.query.page,
      limit: route.query.limit,
      active: route.query.active,
    })
  },
  {
    immediate: true
  }
)
</script>

<template>
  <div class="space-y-5">
    <div class="space-y-2">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink as-child>
              <RouterLink to="/">
                {{ $t('common.dashboard') }}
              </RouterLink>
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <BreadcrumbPage>
              {{ $t("pages.campaign.breadcrumb.campaigns") }}
            </BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <PageHeading
        :title="$t('pages.campaign.title')"
      >
        <template #actions>
          <CampaignCreateButtonModal />
        </template>
      </PageHeading>
    </div>

    <Tabs
      :default-value="$route.query.active || 'all'"
      class="w-[400px]"
      @update:model-value="onStatusChanged"
    >
      <TabsList>
        <TabsTrigger value="all">
          {{ $t('pages.campaign.status.all') }}
        </TabsTrigger>
        <TabsTrigger value="true">
          {{ $t('pages.campaign.status.active') }}
        </TabsTrigger>
        <TabsTrigger value="false">
          {{ $t('pages.campaign.status.disabled') }}
        </TabsTrigger>
      </TabsList>
    </Tabs>

    <BaseTable
      :loading="isLoading"
      :error="hasError"
      :headers="campaignTableHeader"
      :columns="campaigns"
    >
      <template #col-active="{ column }">
        <CampaignStatusBadge :status="column.active" />
      </template>
      <template #actions="{ column }">
        <div class="flex justify-end">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger as-child>
                <Button
                  variant="ghost"
                  size="xs"
                  as-child
                >
                  <router-link
                    :to="{
                      name: 'Campaign',
                      params: {
                        id: column.id,
                      },
                    }"
                  >
                    <PencilIcon class="h-5 text-muted-foreground" />
                  </router-link>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>
                  {{ $t('common.edit') }}
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger as-child>
                <Button
                  variant="ghost"
                  size="xs"
                  as-child
                >
                  <router-link
                    :to="{
                      name: 'Campaign',
                      params: {
                        id: column.id,
                      },
                    }"
                  >
                    <EyeIcon class="h-5 text-muted-foreground" />
                  </router-link>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>
                  {{ $t('common.show') }}
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider v-if="column.mode === 'advanced'">
            <Tooltip>
              <TooltipTrigger as-child>
                <Button
                  variant="ghost"
                  size="xs"
                  as-child
                >
                  <router-link
                    :to="{
                      name: 'CampaignIntegrations',
                      params: {
                        id: column.id,
                      },
                    }"
                  >
                    <ArrowDownTrayIcon class="h-5 text-muted-foreground" />
                  </router-link>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>
                  {{ $t('common.integrations') }}
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger as-child>
                <Button
                  variant="ghost"
                  size="xs"
                  :disabled="isCloning"
                  :loading="isCloning"
                  @click="cloneCampaign(column.id)"
                >
                  <DocumentDuplicateIcon class="h-5 text-muted-foreground" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>
                  {{ $t('common.clone') }}
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <DeleteCampaignModal
            :id="column?.id"
            :name="column?.name"
            @deleted="refresh"
          />
        </div>
      </template>
      <template #pagination>
        <Pagination
          :total="count"
        />
      </template>
      <template #empty>
        <EmptyState
          :title="$t('pages.campaign.empty_state.title')"
          :description="$t('pages.campaign.empty_state.description')"
        >
          <div class="mt-5 grid w-full grid-cols-1 justify-center gap-3 sm:flex sm:items-center">
            <CampaignCreateButtonModal />
          </div>
        </EmptyState>
      </template>
    </BaseTable>
  </div>
</template>
